import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import ProjectHero from "../components/ProjectHero";
import "./Article.css"
import ProjectCard from "../components/ProjectCard";


export default props => {
    return (
        <Layout>
            <ProjectHero
                title="GSA Online Portal"
                intro="In our Capstone project, my team redesigned the GSA website — an internal knowledge management system used by Amazon Global Security Operations."
                client="Amazon Global Security Operations "
                team="Team of 3, 10 weeks in 2019"
                myrole="User research, Product definition, Wire-framing, Concept testing, UI design"
                />

        <div className="VideoWrap">
            <iframe src="https://player.vimeo.com/video/324119845?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>



        {/** :::: INTRO :::: **/}

        <div className="ArticleSection">
            <h1 style={{color:"#FD8C25"}}>Challenge —―</h1>
            <div className="ArticleBody">
            <h2>"Ten seconds, two clicks"</h2>
            <p>Remember the last time you tried to find something in your corp net? You're sure there's a guide doc or a web app somewhere to help you out, but you don't know what exactly you're looking for , or how to find it. After 30 minutes of randomly searching and asking around, you finally get this url and type it into your browser, only to find that you don't have access to the link—or worse, that the link has been deprecated.</p>
            <p>Locating online tools and resources is a top challenge for Amazon's Loss Prevention(LP) people. At Amazon, LP is responsible for protecting Amazon people, products, and data in fulfillment centers, and LP people rely heavily on a variety of websites and online documents to manage building security and investigate incidents. Resouce finding can be extremely time-consuming—a survey showed that looking up resources could take up as much as 40% of an LP person's work time.</p>
            <p>Security Operation Support(SOS), the LP support team, set out to solve this problem. As the sponsors of our capstone project, they came to us with this challenge: <em>how can we enable LP people to find whatever they need in "ten seconds and two clicks"?</em></p>
            <p><br/></p>
            </div>
        </div>


        {/** :::: DISCOVER :::: **/}

        <div className="ArticleSection">
            <h1 style={{color:"#FD8C25"}}>Discover —―</h1>
            <div className="ArticleBody">
            <h2>First up—building empathy with our users</h2>
            <p>What is Loss Provention? What do LP people do? To learn more about our users, we conducted semi-structured interviews with both end users (LP professionals) and subject matter experts (training managers). Below I created a diagram to summarize our key findings.</p>
            </div>
        </div>

        <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.persona.childImageSharp.fluid} /></div>
        </div>


        <div className="ArticleSection">
            <div className="ArticleBody">
            <h2>What makes resource-finding so difficult?</h2>
            <p> Initially our client assumed that a better search functionality could serve as a silver bullet. Is that really the case? </p>
            <p>To figure that out, we collected "failure stories" from 140+ LP professionals ("Tell us a time when you had trouble finding an online resource"), and mapped those failure stories on a journey map to pinpoint where things went wrong. </p> 
            <p>It turned out that <em>a better searching experience may only solve 1/3 of the problem</em>. We found three major gaps in the current user journey:</p>
            </div>
        </div>


        <div className="BackgroundWrap">
            <div className="FullImage"><Img fluid={props.data.userJourney.childImageSharp.fluid} /></div>
        </div>

        {/* <div className="Background">
            <div className="ArticleSection">
            <img src={require("../images/gsa-online-portal/user-journey.png")} />
            </div>
        </div> */}

        <div className="ArticleSection">
            <div className="ArticleBody">
            <p>Targeting at the three gaps, we defined three design goals: <br /><br />
            <em>GOAL 1:</em> Help users discover and learn about new resources <br />
            <em>GOAL 2:</em> Allow users to search resources<br />
            <em>GOAL 3:</em> Enable users to quickly access their saved resources</p>
            <p><br/></p>
            </div>
        </div>


        {/** :::: IDEATE :::: **/}

        <div className="ArticleSection">
            <h1 style={{color:"#FD8C25"}}>Ideate —―</h1>
            <div className="ArticleBody">
            <h2>Structured brainstorm</h2>
            <p>Moving forward, we broke each goal down to several how-might-we questions, and brainstormed for each question to generate product ideas.</p>
            </div>
        </div>


        <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.sketch.childImageSharp.fluid} /></div>
        </div>


        <div className="ArticleSection">
            <div className="ArticleBody">
            <p>Here's an overview of our top ideas:</p>
            </div>
        </div>

        <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.valueProps.childImageSharp.fluid} /></div>
            <p></p><br/>
        </div>

        {/* <div className="ArticleSection">
            <div className="ArticleBody">
            <p>Below I illustrated how we imagine the future GSA website to be working for both end users (LP) and various stakeholders.</p>
            </div>
        </div> */}
{/* 
        <div className="BackgroundWrap">
            <div className="FullImage"><Img fluid={props.data.interactionModel.childImageSharp.fluid} /></div>
        </div>
        <p><br/></p> */}
        

        {/** :::: PROTOTYPE :::: **/}

        <div className="ArticleSection">
            <h1 style={{color:"#FD8C25"}}>Test —―</h1>
            <div className="ArticleBody">
            <h2>Low-fidelity prototyping</h2>
            <p>Next, we identified core user flows and turned our ideas into low-fidelity wireframes.</p>
            </div>
        </div>

        <div className="BackgroundWrap">
            <div className="FullImage"><Img fluid={props.data.loFi.childImageSharp.fluid} /></div>
        </div>


        <div className="ArticleSection">
            <div className="ArticleBody">
            <h2>Concept testing</h2>
            <p>Before diving deeper into design details, we conducted five concept tests with future users to check out if our value prop makes sense. Overall, we heard great feedback from testing participants —</p>
            </div>
        </div>

        <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.quotes.childImageSharp.fluid} /></div>
        </div>


        <div className="ArticleSection">
            <div className="ArticleBody">
            <br />
            <h2>Missed user need: Instant access to saved resources </h2>
            <p>Meanwhile, participants also pointed out areas we overlooked. For example, three (out of five) participants mentioned <em>the importance of being able to quickly access saved resources</em>. We refined related pages based on that feedback.</p>
            </div>
        </div>

        <div className="BackgroundWrap">
            <div className="FullImage">
            <Img fluid={props.data.refine1.childImageSharp.fluid} />
            <br />
            <Img fluid={props.data.refine2.childImageSharp.fluid} />
            </div>
        </div>
        <br/><br/>


        {/** :::: DELIVER :::: **/}

        <div className="ArticleSection">
            <h1 style={{color:"#FD8C25"}}>Deliver —―</h1>
            <div className="ArticleBody">
            <h2>Final Deliverables</h2>
            <p>Now that we had made the major design decisions, we moved forward to finalize and document the design. We handed over our design to Amazon with a complete kit including (1) IA diagrams; (2) Flowcharts; (3) Annotated wireframes; (4) Style guides; and (5) UI mockups.</p>
            </div>
        </div>

        <div className="ArticleSection">
            <div className="ArticleBody">
            <h2>01 One location for all GSA resources</h2>
            <p>The biggest complaint from both users and stakeholders was that resources were "spread everywhere". Currently, users would need to use 3~5 different websites to search for one resource.
            
            <br /><br />We proposed to build a resource library that serves as a one-stop shop for all GSO resources, where each resource is formatted as a "resource card". That'd enable users to easily find any resource, check out what the resource is about, and save the resource to their own bookmark within one website.</p>
            </div>
        </div>

        <div className="BackgroundWrap">
            <div className="FullImage">
            <img src={require('../images/gsa-online-portal/mock-up-1.gif')} />
            </div>
        </div>


        <div className="ArticleSection">
            <div className="ArticleBody">
            <h2>02 Never lose access to saved resources</h2>
            <p>From research we learned that a bummer for many people was the sudden loss of access to their saved resource links. It happened when they needed immediate access to the tool, and often took them forever to find an alternative resource. 
            
            <br /><br />In our design, a GSA admin is responsible to log in change info, so that when a resource deprecates, users will receive notice instantly. They can also find alternative resources within one click.</p>
            </div>
        </div>

        <div className="BackgroundWrap">
            <div className="FullImage">
            <img src={require('../images/gsa-online-portal/mock-up-2.gif')} />
            </div>
        </div>

        <div className="ArticleSection">
            <div className="ArticleBody">
            <h2>03 Lean on each other's knowledge</h2>
            <p>Team collection allows people share to useful resources with team members. That could help new hires to quickly familiarize with their resources, or allow old hands to share their experience before moving to another position.
            
            <br /><br />When we presented that concept to users, many expressed the wish to check out not only their team, but also other teams' collections, since they'd love to discover tools and resources that are potentially useful for them. We iterated our design to respond to that user need.</p>
            </div>
        </div>

        <div className="BackgroundWrap">
            <div className="FullImage">
            <img src={require('../images/gsa-online-portal/mock-up-3.gif')} />
            </div>
        </div>

        <div className="ArticleSection">
            <div className="ArticleBody">
            <h2>04 Resource sharing: pull, not push</h2>
            <p>A common challenge for resource managers / owners was to reach to users to promote new resources. Currently, most people promote new resources via emails, which eventually get buried in end users' busy inbox.
            
            <br /><br />To make resource sharing more effective, we proposed "GSA blog". GSA blog provides a flexible way to share resources organized by topic. It could be an SME writing about new tools coming out, or an LP lead sharing best practice with certain tools. This way, users can choose to receive monthly news letter to review top blogs, or search a certain topic to check out related articles. 
            
            <br /><br />GSA blog also allows authors to insert "Resource Cards" in their articles, so that if a reader finds any resource interesting, she can save it with just one click.</p>
            </div>
        </div>

        <div className="BackgroundWrap">
            <div className="FullImage">
            <img src={require('../images/gsa-online-portal/mock-up-4.gif')} />
            </div>
        </div>


        <div className="ArticleSection">
            <div className="ArticleBody">
            <h2>Behind the scenes: mapping out the content management workflow</h2>
            <p>While our main focus was on the user experience, we always kept in mind that content is the key to the success of this platform. To address that, we helped our client map out the content management workflow to show how different stakeholders can work together to support the new GSA website.</p>
            </div>
        </div>
        
        <div className="BackgroundWrap">
            <div className="FullImage"><Img fluid={props.data.interactionModel.childImageSharp.fluid} /></div>
        </div>
        <p></p>
        


        <p><br/></p>

        {/** :::: OUTRO :::: **/}

        <div className="ArticleSection">
            <h1 style={{color:"#FD8C25"}}>Outro —―</h1>
            <div className="ArticleBody">
            <h2>Results</h2>
            <p>In April, we presented the design at Amazon and received great feedback from our sponsors. They were impressed by the completeness of our work and appreciated our efforts in making the website easy to build and maintain. Moving forward, the team at Amazon will use our design as a starting point to build the GSA website. </p>

            <p><br/> DEVISION OF LABOR: <br/>
            Me: Research, product definition, wire-framing, testing & UI design<br />
            Natalie Huang: Ideation, design of style guide, wire-framing, testing & UI design<br />
            Meng Wang: IA research, design & testing</p>
            </div>
        </div>
        <p><br/></p>

        <div className="ArticleSection">
          <div className="Next">
            <h6 style={{color:"#8E8E8E"}}>Next up</h6>
            <ProjectCard 
            link="/start/"
            title="START"
            tagline="Streamline the car booking experience for a peer-to-peer car-sharing platform"
            tag="Mobile App • Internship Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/start.png')}
            />
          </div>
        </div>


        </Layout>
    )
}


export const query = graphql`
  query {
    persona: file(relativePath: { eq: "gsa-online-portal/persona.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    userJourney: file(relativePath: { eq: "gsa-online-portal/user-journey.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    
    sketch: file(relativePath: { eq: "gsa-online-portal/sketch.jpg" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    
    valueProps: file(relativePath: { eq: "gsa-online-portal/value-props.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    interactionModel: file(relativePath: { eq: "gsa-online-portal/interaction-model.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }


    loFi: file(relativePath: { eq: "gsa-online-portal/lo-fi.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }


      quotes: file(relativePath: { eq: "gsa-online-portal/quotes.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      refine1: file(relativePath: { eq: "gsa-online-portal/refine-1.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      refine2: file(relativePath: { eq: "gsa-online-portal/refine-2.png" }){
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }



  }
`
