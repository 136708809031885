import React from 'react'
import styled from 'styled-components'

import Button from "../components/Button";

const CardGroup = styled.div`
    color: white;
    max-width: 960px;
    height: 320px;
    position: relative;
    overflow: hidden;
    margin-bottom: 36px;
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(0,0,0,0.25);
    display: absolute;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    
    &:hover {
    transform: scale(1.02,1.02);
    box-shadow: 0 30px 60px rgba (0,0,0,0.5);
    }

    @media (max-width: 960px) {
    width: 100%;
    }

`
const CardForground = styled.img`
    position: absolute;
    right: 15%;
    bottom: -20%;
    height: 100%;
    z-index: 2;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    ${CardGroup}:hover & {
        transform: translateY(-20px);
    }
`

const CardBackground = styled.img`
    position: absolute;
    height: 110%;
    right: -10%;
    bottom: -10%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    ${CardGroup}:hover & {
        transform: translateY(-20px);
    }

    @media (max-width: 640px) {
        left: -30%;
    }

    @media (max-width: 960px) {
        left: -10%;
    }

`

const CardContent = styled.div`
    margin-left: 32px;
`

const CardTag = styled.h4`
    margin-top: 32px ;
`

const CardTitle = styled.h2`
    font-weight: 700;
    letter-spacing: 0;
    margin-top: 56px;
`

const CardTagline = styled.p`
    position: relative;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.3px;
    max-width: 304px;
    margin-top: 0;

`

const CardButton = styled.a `
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: white;
    margin: 0;
`

const ProjectCard = props => (
    <CardGroup> 
        <CardForground src={props.image} />
        <CardBackground src={props.background} />
        <CardContent>
            <CardTag>{props.tag}</CardTag>
            <CardTitle>{props.title}</CardTitle>
            <CardTagline>{props.tagline}</CardTagline>
            <CardButton href={props.link}>View →</CardButton>
        </CardContent>
    </CardGroup>
)

export default ProjectCard



// <CardButton>
// <Button text="View" onDark="Y" link={props.link}/>
// </CardButton>